import React from 'react';
import styled from 'styled-components/macro';

const CardInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    
`;
const Card = styled.div`
    background-color: transparent;
    margin:1.2em;
    width: 300px;
    height: 169px;
    /* border: 1px solid #f1f1f1; */
    perspective: 1000px; /*Remove this if you don't want the 3D effect*/
&:hover ${CardInner}{
        transform: rotateY(180deg);
    }
`;
const CardFront = styled.div`
position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background-color: #bbb;
    color: black;
`;
const CardBack = styled.div`
position: absolute;
    width: 100%;
    height: 100%;
    padding:2%;
    backface-visibility: hidden;
    background-color: #630606;
    color: lightyellow;
    transform: rotateY(180deg);
`;

const CardImg = styled.img`
    /* width: 300px;
   height:170px; */
`;

const FlipCard = (props) => {
    return (
        <Card>
            <CardInner>
                <CardFront>
                    <CardImg src={props.image} alt={props.desc} width="300" height="169" />
                    <p>{props.title}</p>
                </CardFront>
                <CardBack>
                    <h2>{props.title}</h2>
                    <p>
                        {props.desc}
                    </p>
                </CardBack>
            </CardInner>
        </Card>
    )
}


export default FlipCard