import React from 'react';
import Logo from './IMT-Plain.svg';
import styled from 'styled-components/macro';

const BrandWrapper = styled.div`
    margin-top: 30px;
    text-align: center;
    a{
    color:white;
    }
    p{
    margin-top: 5px;
    }
`;


const Brand = () => {
    return (
        <BrandWrapper>
            <a href="https://imaginemoretechnologies.com/">
                <img src={Logo} alt="Imagine More Technologies" height="25px" />
                <p>Built by Imagine More Technologies</p>
            </a>
        </BrandWrapper>
    )
}

export default Brand