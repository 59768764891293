import React from 'react';
import styled from 'styled-components/macro';

const County = styled.span`
    font-size: 1.5em;
    font-weight: bold;
`;
const CountyLocation = (props) => {
    return (
        <p>
            <County>{props.children}</County>
        </p>
    )
}

export default CountyLocation