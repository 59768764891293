import React from 'react';
import styled from 'styled-components/macro';

const ListItems = styled.div`
li{
    font-size: 2em;
    list-style-type: none;
}
@media(max-width:400px){
    li{
        font-size: 1.5em;
    }
    }

`;

const MenuLi = (props) => {
    return (
        <ListItems>
            {props.children}
        </ListItems>
    )
}

export default MenuLi