import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FancyHR from '../components/FancyHR';
// import styled from 'styled-components/macro';
import Link from '../components/Link';
import CountyLocation from '../components/locations/CountyLocation';
import TownLocation from '../components/locations/TownLocation';
import ServiceLocation from '../components/locations/ServiceLocation'
import SectionTitle from '../components/SectionTitle';


const Locations = () => {
    return (
        <Container id="locations">
            <FancyHR />
            <Row>
                <Col>
                    <SectionTitle>Locations</SectionTitle>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p> Our most common service locations are listed here by County.
                    If your planned event is not listed don't worry, we provide
                    full service to all of Michigan including private residences!
                    Click <Link href="#quote">here</Link> to receive a quote for
                    your special event.
                    </p>
                </Col>
            </Row>

            <Row >
                <Col>
                    <CountyLocation>Iosco County</CountyLocation>
                </Col>
            </Row>


            <Row >
                <Col xs={{ offset: 1 }}>

                    <TownLocation>Hale:</TownLocation>


                </Col>
            </Row>


            <Row className="text-center">

                <Col lg={6}>
                    <ServiceLocation title="American Legion">
                        429 Esmond Rd <br />
                        Hale, MI 48739
                </ServiceLocation>
                </Col>





                <Col lg={6}>
                    <ServiceLocation title="Eagles Club">
                        4160 M-65 <br />
                        Hale, MI 48739
                </ServiceLocation>
                </Col>
            </Row>

            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="KC Hall">
                        3123 M-65 <br />
                    Hale, MI 48739
                </ServiceLocation>
                </Col>



                <Col lg={6}>
                    <ServiceLocation title="Plainfield Twp Hall">
                        220 N Washington St<br />
                    Hale, MI 48739
                </ServiceLocation>
                </Col>
            </Row>


            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="VFW">
                        220 Ainsley St<br />
                    Hale, MI 48739
                </ServiceLocation>
                </Col>
            </Row>



            <Row >
                <Col xs={{ offset: 1 }}>
                    <TownLocation>Oscoda:</TownLocation>
                </Col>
            </Row>



            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="American Legion">
                        349 S State St<br />
                    Oscoda, MI 48750
                </ServiceLocation>
                </Col>

                <Col lg={6}>
                    <ServiceLocation title="VFW Post 3735">
                        240 CHRYSLER RD <br />
                    Oscoda, MI 48750
                </ServiceLocation>
                </Col>
            </Row>



            <Row>
                <Col xs={{ offset: 1 }}>
                    <TownLocation>Tawas:</TownLocation>
                </Col>
            </Row>



            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="Community Center">
                        760 Newman East <br />
                    Tawas, MI 48730
                </ServiceLocation>
                </Col>

                <Col lg={6}>
                    <ServiceLocation title="Elks Club">
                        8126 Monument East <br />
                    Tawas, MI 48730
                </ServiceLocation>
                </Col>
            </Row>

            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="KC Hall">
                        821 Newman St. East <br />
                    Tawas, MI 48730
                </ServiceLocation>
                </Col>



                <Col lg={6}>
                    <ServiceLocation title="VFW">
                        1419 Lake St <br />
                    Tawas City, MI 48730
                </ServiceLocation>
                </Col>
            </Row>


            <Row>

                <Col>
                    <CountyLocation>Ogemaw County</CountyLocation>
                </Col>
            </Row>


            <Row>
                <Col xs={{ offset: 1 }}>
                    <TownLocation>West Branch:</TownLocation>
                </Col>
            </Row>

            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="KC Hall">
                        601 Columbus Ave. <br />
                    West Branch, MI 48661
                </ServiceLocation>
                </Col>
                <Col lg={6}>
                    <ServiceLocation title="Ogemaw County Fairgrounds">
                        2300 Rifle River Trail <br />
                    West Branch, MI 48661
                </ServiceLocation>
                </Col>
            </Row>



            <Row >

                <Col>
                    <CountyLocation>Arenac County</CountyLocation>
                </Col>
            </Row>


            <Row >
                <Col xs={{ offset: 1 }}>
                    <TownLocation>Au Gres:</TownLocation>
                </Col>
            </Row>

            <Row className="text-center">
                <Col lg={6}>
                    <ServiceLocation title="American Legion">
                        US-23<br />
                    Au Gres, MI 48703
                </ServiceLocation>
                </Col>
                <Col lg={6}>
                    <ServiceLocation title="KC Hall">
                        263 S. Main Street <br />
                    Au Gres, MI 48703
                </ServiceLocation>
                </Col>
            </Row>

        </Container>

    )
}

export default Locations