import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FancyHR from '../components/FancyHR';
import SectionTitle from '../components/SectionTitle';
import MenuFlipCards from '../components/MenuFlipCards';






const Menus = () => {
    return(
    <Container id="menus">
        <FancyHR />
        <Row>
            <Col>
                <SectionTitle>Menus</SectionTitle>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    At Up North Catering our goal is to provide the best food at the best price.  Our menus are perfect for your planned events like weddings, dinners, and graduations.  We guarantee that our quality is unmatched and your guests will agree.
                </p>

            </Col>
        </Row>
        <MenuFlipCards />
    </Container>
    )
}

export default Menus