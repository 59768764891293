import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ChalkBoard from '../../components/ChalkBoard';
import MenuLi from '../../components/menu/MenuLi';
import MenuItemTitles from '../../components/menu/MenuItemTitles';
import ChalkBoardColPadding from '../../components/ChalkBoardColPadding';


const Lunch = () => {
    return (
        <Container>
            <ChalkBoard id="lunchMenu" menuTitle="Lunch Menu">
                <Row>
                    <ChalkBoardColPadding md={6} lg={4} className="text-center">
                        <MenuItemTitles color="pink" title="Main Courses" />
                        <MenuLi>
                            <li>Ham</li>
                            <li>Turkey</li>
                            <li>Roast Beef</li>
                            <li>Hard Salami</li>
                            <li>Chicken Salad</li>
                            <li>Turkey Salad</li>
                            <li>Ham Salad</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                    <ChalkBoardColPadding md={6} lg={4} className="text-center">
                        <MenuItemTitles color="yellow" title="Cheeses" />
                        <MenuLi>
                            <li>American</li>
                            <li>Swiss</li>
                            <li>Cheddar</li>
                            <li>Mozzarella</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                    <ChalkBoardColPadding md={12} lg={4} className="text-center">
                        <MenuItemTitles color="lightblue" title="Salad Dishes" />
                        <MenuLi>
                            <li>Fresh Fruit</li>
                            <li>Jell-O</li>
                            <li>Rotini Salad</li>
                            <li>Potato Salad</li>
                            <li>Macaroni Salad</li>
                            <li>Spaghetti Salad</li>
                            <li>Coleslaw</li>
                            <li>Cottage Cheese</li>
                            <li>Ambrosia Salad</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                </Row>
            </ChalkBoard>
        </Container>

    )
}

export default Lunch