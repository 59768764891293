import React from 'react';
import NavBar from '../sections/NavBar';
import Footer from '../sections/Footer';
import BBQ from '../sections/menus/BBQ';
import Contact from '../sections/Contact';
import Quote from '../sections/Quote';
import MenuMessage from '../sections/MenuMessage';

const BBQMenu = () => (
    <>
        <NavBar />
        <MenuMessage />
        <BBQ />
        <Quote />
        <Contact />
        <Footer />
    </>
)

export default BBQMenu