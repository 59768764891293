import React from 'react';
import AutoCopyright from '../components/AutoCopyright';
import Brand from '../components/brand/Brand';
import styled from 'styled-components/macro';

const CopyrightLogo = styled.div`
    text-align: center;
    font-size: 12px;
    color: white;
    width: 100%;
    background-color: #373536;
    padding-top: 20px;
    padding-bottom: 20px;
`;
// const CopyrightP = styled.p`

// `;

const Footer = () => {
  return(
    <footer>
        <CopyrightLogo>
          <p>
            <AutoCopyright 
            year={2015} 
            text="Up North Catering"/>
          </p>
          <Brand />
        </CopyrightLogo>
      </footer>
  )
}

export default Footer