import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import HomePage from './pages/HomePage';
import DinnerMenu from './pages/DinnerMenu';
import LunchMenu from './pages/LunchMenu';
import BBQMenu from './pages/BBQMenu';
import HorsDoeuvresMenu from './pages/HorsDoeuvresMenu';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Route path="/" component={HomePage} exact />
        <Route path="/dinner-menu" component={DinnerMenu} />
        <Route path="/lunch-menu" component={LunchMenu} />
        <Route path="/bbq-menu" component={BBQMenu} />
        <Route path="/hors-doeuvres-menu" component={HorsDoeuvresMenu} />
      </div>
    </Router>
  );
}

export default App;
