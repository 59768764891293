import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChalkBoard from '../../components/ChalkBoard';
import MenuLi from '../../components/menu/MenuLi';
import MenuItemTitles from '../../components/menu/MenuItemTitles';
import ChalkBoardColPadding from '../../components/ChalkBoardColPadding';
import Notes from '../../components/Notes';


const Dinner = () => {
    return (
        <Container>
            <ChalkBoard id="dinnerMenu" menuTitle="Dinner Menu">
                <Row>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="pink" title="Main Courses" />
                        <MenuLi>
                            <li>Carved Roast Beef</li>
                            <li>Carved Fresh Ham</li>
                            <li>Turkey Breast</li>
                            <li>Baked Chicken</li>
                            <li>BBQ Chicken</li>
                            <li>Baked Chicken Breast</li>
                            <li>Stuffed Chicken Breast *</li>
                            <li>Lasagna</li>
                            <li>Polish Sausage and Sauer Kraut</li>
                            <li>Swedish Meatballs</li>
                            <li>BBQ Meatballs</li>
                            <li>Meatballs in Brown Gravy</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="yellow" title="Entrees" />
                        <MenuLi>
                            <li>Mashed Potatoes</li>
                            <li>Diced Redskin Potatoes</li>
                            <li>Baked Potatoes</li>
                            <li>Scalloped Potatoes</li>
                            <li>Au Gratin Potatoes</li>
                            <li>Rice Pilaf</li>
                            <li>Baked Beans</li>
                            <li>Stuffing</li>
                            <li>Yams</li>
                            <li>Homemade Egg Noodles</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                </Row>
                <Row>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="lightblue" title="Side Dishes" />
                        <MenuLi>
                            <li>Corn</li>
                            <li>Green Beans</li>
                            <li>Green Bean Casserole</li>
                            <li>California Blend Vegetables</li>
                            <li>Glazed Carrots</li>
                            <li>Peas and Carrots</li>
                        </MenuLi>
                    </ChalkBoardColPadding>

                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="green" title="Cold Side Dishes" />
                        <MenuLi>
                            <li>Potato Salad</li>
                            <li>Macaroni Salad</li>
                            <li>Tossed Salad</li>
                            <li>Spaghetti Salad</li>
                            <li>Rotini Salad</li>
                            <li>Coleslaw Salad</li>
                            <li>Jell-O Salad</li>
                            <li>Cottage Cheese</li>
                            <li>Fresh Fruit Salad</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                </Row>
                <Row>
                    <Col>
                        <Notes>
                            <em>All events include dinner rolls and butter.</em><br /><br />
                            <em>* $1.00 extra per person</em>
                        </Notes>
                    </Col>
                </Row>
            </ChalkBoard>
        </Container>



    )
}

export default Dinner