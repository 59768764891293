import React from 'react';
import { Link } from 'react-router-dom';
import Lunch from '../img/lunch.jpg';
import Dinner from '../img/dinner.jpg';
import BBQ from '../img/bbq.jpg';
import HorD from '../img/horD.jpg';
import FlipCard from './flipcard/FlipCard';


const MenuFlipCards = () => {
    return (
        <div className="d-flex flex-wrap justify-content-center align-items-center">
            <Link to="dinner-menu" >
                <FlipCard
                    image={Dinner}
                    title="Dinner"
                    desc="Click to view our delicious dinner menu."
                />
            </Link>
            <Link to="lunch-menu">
                <FlipCard
                    image={Lunch}
                    title="Lunch"
                    desc="Click to view our delicious lunch menu."
                />
            </Link>
            <Link to="bbq-menu">
                <FlipCard
                    image={BBQ}
                    title="BBQ"
                    desc="Click to view our delicious bbq menu."
                />
            </Link>
            <Link to="hors-doeuvres-menu">
                <FlipCard
                    image={HorD}
                    title="Hors D'oeuvres"
                    desc="Click to view our delicious hor d'oeuvres menu."
                />
            </Link>
        </div>
    )
}

export default MenuFlipCards