import React from 'react';
import styled from 'styled-components/macro';
import Button from 'react-bootstrap/Button';
import Link from './Link';

const StyledButton = styled(Button)`
    background-color: #ff0000;
    color: white;
    margin-top: 20px;
    box-shadow: 1px 3px 7px gray;
    &:hover{
        box-shadow: none;
    }
`;
const QuoteButton = () => {
    return (
        <Link href="#quote">
            <StyledButton size="lg" variant="danger" name="button">Free Quote</StyledButton>
        </Link>
    )
}


export default QuoteButton