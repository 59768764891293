import React from 'react';
import styled from 'styled-components/macro';

const NoteDiv = styled.div`
padding-top: 40px;
p{
    font-size:.8em;
}
`;
const Notes = (props) => {
  return (
        <NoteDiv>
            <p>{props.children}</p>
        </NoteDiv>
    )
}

export default Notes