import React from 'react';
import styled from 'styled-components/macro';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../components/PageHeader';
// import QuoteButton from '../components/QuoteButton';
import menus from '../img/menus.jpg';

const StyledContainer = styled(Container)`
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)), url(${menus});
  background-position: top center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  height: 100%;
  background-size: cover;
 
  
`;

const Splash = () => {
    return (
        <StyledContainer fluid>
            <Row>
                <Col>
                    <PageHeader />
                    {/* <QuoteButton /> */}
                </Col>
            </Row>
        </StyledContainer>
    )
}

export default Splash