import React from 'react';
import styled from 'styled-components/macro';

const Town = styled.span`
    font-size: 1.25em;
    color: rgb(255, 0, 0);
`;
const TownLocation = (props) => {
    return (
        <p>
            <Town>{props.children}</Town>
        </p>
    )
}

export default TownLocation