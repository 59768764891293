

const AutoCopyright = ({ year, text }) => {
    var dateObject = new Date(); // get this year as 4-digit value
    var thisYear = dateObject.getFullYear();


    if (year === thisYear || year > thisYear) { // $year cannot be greater than this year - if it is then echo only current year
        return `Copyright \u00A9 ${thisYear} ${text}. All Rights Reserved`; // display single year
    } else {
        return `Copyright \u00A9 ${year} - ${thisYear} ${text}. All Rights Reserved`; // display range of years
    }

}

export default AutoCopyright