import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components/macro';
import Link from '../components/Link';

const StyledContainer = styled(Container)`
margin-top: 6em;
`;

const MenuMessage = () => {
    return (
        <StyledContainer>
            <Row>
                <Col>
                    <p>
                        If you have questions or would like to ask about alternative food options please use the <Link href="#quote">quote</Link> form below.
                </p>
                </Col>
            </Row>
        </StyledContainer>
    )
}

export default MenuMessage;