import React from 'react';
import styled from 'styled-components/macro';

const Fancy = styled.div`
    margin-top: 40px;
    margin-bottom: 20px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgb(255, 0, 0), rgba(255, 0, 0, 0));

`;

const FancyHR = () => {
    return(
    <div className="text-center"><Fancy /></div>
    )
}

export default FancyHR