import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Link from '../components/Link';
import SectionTitle from '../components/SectionTitle';



const Welcome = () => {
    return(
    <Container  className="mt-4" id="welcome">
        <Row>
            <Col>
                <SectionTitle>Welcome!</SectionTitle>
            </Col>
        </Row>

        <Row>
            <Col>
                <p>At Up North Catering we aim to provide you with delicious high quality food at your most precious of
                events. No event is too large or too small for our excellent service. We have over 26 years of experience
                serving Northeastern Michigan. Our friendly staff will ensure that your event is fully catered, with
      mouth-watering and attractive displays of food. You, the customer, are our number one priority! </p>
                <p>
                    The <Link href="#quote">quote</Link> form is the fastest way to guarantee your date for that special event!
                </p>
            </Col>
        </Row>
    </Container>
    )
}

export default Welcome