import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ChalkBoard from '../../components/ChalkBoard';
import MenuLi from '../../components/menu/MenuLi';
import MenuItemTitles from '../../components/menu/MenuItemTitles';
import ChalkBoardColPadding from '../../components/ChalkBoardColPadding'
import Notes from '../../components/Notes';


const BBQ = () => {
    return (
        <Container>
            <ChalkBoard id="bbqMenu" menuTitle="BBQ Menu">
                <Row>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="pink" title="Main Courses" />
                        <MenuLi>
                            <li>Roasted Pig</li>
                            <li>Pulled Pork</li>
                            <li>Hamburgers</li>
                            <li>Hotdogs</li>
                            <li>Steaks</li>
                            <li>BBQ Chicken</li>
                            <li>BBQ Chicken Breast</li>
                            <li>Bratwursts</li>
                            <li>Polish Sausage</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="yellow" title="Side Dishes" />
                        <h3><b></b></h3>
                        <MenuLi>
                            <li>Baked Beans</li>
                            <li>Au gratin Potatoes</li>
                            <li>Scalloped Potatoes</li>
                            <li>Baked Potatoes</li>
                            <li>Diced Redskin Potatoes</li>
                        </MenuLi>

                    </ChalkBoardColPadding>

                    <ChalkBoardColPadding md={12} className="text-center">
                        <MenuItemTitles color="lightblue" title="Cold Salad Dishes" />
                        <MenuLi>
                            <li>Potato Salad</li>
                            <li>Macaroni Salad</li>
                            <li>Fresh Fruit Salad</li>
                            <li>Rotini Salad</li>
                            <li>Spaghetti Salad</li>
                            <li>Cottage Cheese</li>
                            <li>Jell-O</li>
                        </MenuLi>

                    </ChalkBoardColPadding>
                </Row>

                <Row>
                    <Col>
                        <Notes>
                            <em>All events are provided rolls with butter.</em><br /><br />
                            <em>Paper products are a $1.50 per person.</em>
                        </Notes>
                    </Col>
                </Row>
            </ChalkBoard>
        </Container>

    )
}

export default BBQ