import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import styled from 'styled-components/macro';
import { ShiftWindow } from '../components/Helpers';


const Red = styled.span`
    color:red;
`;

const StyledNavbar = styled(Navbar)`
font-size:1.3em;
font-family: "Swash";
/* height:50px; */
border-color: rgba(186, 186, 186, .3);
background-color: rgb(97, 97, 97);



/* Make Brand Text White */
&.navbar-light .navbar-brand{
    color:white;
    font-size:1em;
}
/*  */
&.navbar-light .navbar-nav .nav-link{
    color:white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.63);
}
/*  */
&.navbar-light .navbar-nav .nav-link:hover{
    color: black !important;
    /* background-color: white; */
}


@media(max-width:350px){
    &.navbar-light .navbar-brand{
    font-size:.8em;
}

}
`;

const NavBar = () => {

    return (
        <StyledNavbar collapseOnSelect expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="/">UP NORTH <Red>CATERING</Red></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link onClick={ShiftWindow} href="/#">Home</Nav.Link>
                        <Nav.Link onClick={ShiftWindow} href="/#menus">Menus</Nav.Link>
                        <Nav.Link onClick={ShiftWindow} href="/#locations">Service Locations</Nav.Link>
                        <Nav.Link onClick={ShiftWindow} href="/#quote">Quote</Nav.Link>
                        <Nav.Link onClick={ShiftWindow} href="/#contact">Contact</Nav.Link>
                        <NavDropdown.Divider />
                        <Nav.Link href="tel:+19897282082">989-728-2082</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </StyledNavbar>
    )
}

export default NavBar