import React from 'react';
import styled from 'styled-components/macro';
import QuoteButton from './QuoteButton';


const HeadingDiv = styled.div`
margin-top: 20%;
margin-bottom:20%;
@media(max-width:540px){
  margin-bottom:35%; 
margin-top:35%;
}
`;
const Heading = styled.div`
    
    border: none;
   
  span{
    color:red;
  }
  hr{
    border: 0;
    height: 1px;
    width: 50%;
    background-image: linear-gradient(to right, rgba(255, 0, 0, 0), rgba(255, 0, 0, 0.75), rgba(255, 0, 0, 0));
  }
  h1{   
    font-size: 6.8vw;
    font-family:"Swash";
  }
 
`;

const Motto = styled.h2`
        color: white;
        font-size: 3vw;
        font-family:'Comfort';
        @media (max-width: 540px) {
     
          font-size:1em;
      
  }
`;
const PageHeader = () => {
  return (
    <HeadingDiv>
      <Heading>
        <h1>UP NORTH <span>CATERING</span></h1>
        <hr />
      </Heading>
      <Motto>"Try us...our reputation depends on your satisfaction!"</Motto>
      <QuoteButton />
    </HeadingDiv>
  )
}


export default PageHeader;