import React from 'react'
import styled from 'styled-components/macro';

const Title = styled.h2`
    margin-top:20px;
    color: rgb(65, 65, 65);
  font-weight: bold;
  font-family:"Swash";
  font-size:2.3em;
`;
const SectionTitle = (props) => {
  return (<Title>{props.children}</Title>)
}


export default SectionTitle