import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ChalkBoard from '../../components/ChalkBoard';
import MenuLi from '../../components/menu/MenuLi';
import MenuItemTitles from '../../components/menu/MenuItemTitles';
import ChalkBoardColPadding from '../../components/ChalkBoardColPadding';


const HorsDoeuvres = () => {
    return (
        <Container>
            <ChalkBoard id="hMenu" menuTitle="Hors D'oeuvres">
                <Row>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="pink" title="Hot" />
                        <MenuLi>
                            <li>Stuffed Mushrooms</li>
                            <li>Broccoli Quiche</li>
                            <li>Rumaki</li>
                            <li>Chicken and Bacon Wraps</li>
                            <li>Crab Dabs</li>
                            <li>Mini Sausage links in BBQ Sauce</li>
                            <li>Mini Hotdogs in BBQ Sauce</li>
                            <li>Pigs in a Blanket</li>
                            <li>Chicken Kabobs</li>
                            <li>Hawaiian Kabobs</li>
                            <li>Sweet and Sour Meatballs</li>
                            <li>BBQ Meatballs</li>
                            <li>Swedish Meatballs</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                    <ChalkBoardColPadding md={6} className="text-center">
                        <MenuItemTitles color="yellow" title="Cold" />
                        <MenuLi>
                            <li>Salami Cornets</li>
                            <li>Fresh Vegetables with Dip</li>
                            <li>Cheese Balls with Assorted Crackers</li>
                            <li>Cheese Cubes with Assorted Crackers</li>
                            <li>Fresh Fruit Kabobs</li>
                            <li>Fresh Fruit</li>
                            <li>Stuffed Cucumbers</li>
                            <li>Deviled Eggs</li>
                            <li>Stuffed Cherry Tomatoes</li>
                            <li>Mini Sandwiches</li>
                            <li>Ham Roll Up</li>
                            <li>Relish Tray</li>
                        </MenuLi>
                    </ChalkBoardColPadding>
                </Row>
            </ChalkBoard>
        </Container>

    )
}

export default HorsDoeuvres