import React from 'react';
import styled from 'styled-components/macro'

const MenuTitle = styled.h2`
color:${props => props.color || "white"};
font-size:4em;
@media(max-width:400px){
        font-size:2em;
    }
`;

const MenuItemTitles = (props) => {
    return (
        <MenuTitle color={props.color}>{props.title}</MenuTitle>
    )
}

export default MenuItemTitles