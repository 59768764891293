import React from 'react';
import styled from 'styled-components/macro';

const Title = styled.span`
    font-weight:600;
`;
const Address = styled.span`
    color:grey;
`;
const Location = styled.p`
    margin-bottom:20px;
`;
const ServiceLocation = (props) => {
    return (
        <Location>
            <Title>{props.title}</Title> <br />
            <Address>{props.children}</Address>
        </Location>
    )
}

export default ServiceLocation