import React from 'react';
import Welcome from '../sections/Welcome';
import NavBar from '../sections/NavBar';
import Splash from '../sections/Splash';
import Menus from '../sections/Menus';
import Locations from '../sections/Locations';
import Quote from '../sections/Quote';
import Contact from '../sections/Contact';
import Footer from '../sections/Footer';

const HomePage = () => (
    <>
        <NavBar />
        <Splash />
        <Welcome />
        <Menus />
        <Locations />
        <Quote />
        <Contact />
        <Footer />
    </>
);

export default HomePage