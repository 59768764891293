import React from 'react';
import styled from 'styled-components/macro';
import { ShiftWindow } from './Helpers';

const StyledLink = styled.a`
color:red;
:hover{
    color:darkred;
}
`;

const Link = (props) => {
    return (
        <StyledLink onClick={ShiftWindow} href={props.href}>{props.children}</StyledLink>
    )
}

export default Link