import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components/macro';
import chalk from '../img/chalkboard.jpg'

const MenuTitle = styled.h1`
    
    font-family:"Homemade";
    font-size:5em;
    color: red;
    @media(max-width:400px){
        font-size:3em;
    }
`;

const StyledCard = styled(Card)`
background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${chalk});
  background-position: top center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  color:white;
  font-family:"Homemade";
  /* border-radius:10px; */
`;

const ChalkBoard = (props) => {
    return (
        <Row>
            <Col>
                <StyledCard className="shadow-sm" id={props.id}>
                    <Card.Body>
                        <Row>
                            <Col className="text-center">
                                <MenuTitle>{props.menuTitle}</MenuTitle>
                            </Col>
                        </Row>
                        {props.children}
                    </Card.Body>
                </StyledCard>
            </Col>
        </Row>
    )
}

export default ChalkBoard