import React from 'react';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components/macro';

const Column = styled(Col)`
    margin-bottom:20px;
`;


const ChalkBoardColPadding = (props) => {
    return (
        <Column {...props}>{props.children}</Column>
    )
}

export default ChalkBoardColPadding