import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FancyHR from '../components/FancyHR';
import SectionTitle from '../components/SectionTitle';
import Link from '../components/Link';
import styled from 'styled-components/macro';

const ContactDiv = styled.div`
margin-bottom:50px;
`;

const Contact = () => {
    return (
        <Container id="contact">
            <FancyHR />
            <Row>
                <Col>
                    <SectionTitle>Contact Info</SectionTitle>
                </Col>
            </Row>
            <Row>
                <Col>

                    <ContactDiv>
                        <address>
                            <h4>Up North Catering</h4>
                                4690 M-65<br />
                                Hale, MI 48739<br />
                                Tell: <Link href="tel:19897282082">989-728-2082</Link><br />
                                Email: <Link href="mailto:info@upnorthcatering.com?subject=Catering Questions" target="_blank" rel="noopener noreferrer">info@upnorthcatering.com</Link>
                        </address>
                    </ContactDiv>

                </Col>
            </Row>
        </Container>
    )
}

export default Contact