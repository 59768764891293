import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EmailForm from '../components/EmailForm';
import FancyHR from '../components/FancyHR';
import SectionTitle from '../components/SectionTitle';

const Quote = () => {
    return (
        <Container id="quote">
            <FancyHR />
            <Row>
                <Col>
                    <SectionTitle>Quote Form</SectionTitle>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 8, offset: 2 }}>
                    <EmailForm URL="https://p06h5q0ny2.execute-api.us-east-1.amazonaws.com/Dev/contact-email" />
                </Col>
            </Row>
        </Container>

    )
}

export default Quote