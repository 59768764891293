import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//React-Router-Dom Scroll Restoration
//https://reactrouter.com/web/guides/scroll-restoration
export default function ScrollToTop() {
  const { pathname } = useLocation();
  // if pathnamce is greater than a / scroll to top of page
  useEffect(() => {
    if(pathname.length !== 1)
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}